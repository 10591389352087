import { logger } from '@home-mgmt-shared/logger';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const appConfig = require('appConfig');

export const initializeLogger = (): void => {
  const { appName } = appConfig;
  const newContext = {
    url: `${appConfig.loggingApiUrl}${appName}/`,
    appName,
    env: appConfig.configEnv,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logger.updateContext((_) => newContext);
};
