import {
  IRecommendationServiceRequest,
  IWifiWizardAnsweredQuestion,
  IWifiWizardQuestion,
  IWifiWizardRecommendation,
  IWifiWizardResponse,
  IWifiWizardSolution,
} from '../services/contracts';
import { defaultTweekRepository } from '../tweek';

// TODO: Better way to get from tweek?
const shouldCallRecommendationApi = async () => {
  const tweekResponse = await defaultTweekRepository.getValue<boolean>(
    'network_scan/expert_dashboard/customer_questions/call_prediction_api'
  );

  return tweekResponse ?? false;
};

//#region FULL QUESTION LIST
const question1 = {
  id: '101',
  question: "What best describes the customer's issue?",
  questionType: 'singleSelect',
  answers: [
    {
      id: '101001',
      text: 'WiFi is slow',
    },
    {
      id: '101002',
      text: 'WiFi connection drops out',
    },
    {
      id: '101003',
      text: 'Problem with a particular service/website (i.e. Netflix)',
    },
  ],
} as IWifiWizardQuestion;

const question2 = {
  id: '102',
  question: 'Does the customer experience this issue on more than one device?',
  answers: [
    {
      id: '102001',
      text: 'Yes',
    },
    {
      id: '102002',
      text: 'No',
    },
    {
      id: '102003',
      text: 'Unsure',
    },
  ],
  questionType: 'singleSelect',
} as IWifiWizardQuestion;

const question3 = {
  id: '103',
  question: 'When does the customer notice the issue (select all that apply)?',
  answers: [
    {
      id: '103001',
      text: 'All the time',
    },
    {
      id: '103002',
      text: 'Randomly',
    },
    {
      id: '103004',
      text: 'At a consistent time of day',
    },
    {
      id: '103003',
      text: 'In a particular area of their home',
    },
    {
      id: '103005',
      text: 'When several people are using the internet at the same time',
    },
  ],
  questionType: 'multiSelect',
} as IWifiWizardQuestion;

const question4 = {
  id: '105',
  question: 'Has the customer already tried anything to fix the issue?',
  answers: [
    {
      id: '105001',
      text: 'Restarting their router',
    },
    {
      id: '105002',
      text: 'Calling their ISP',
    },
    {
      id: '105003',
      text: 'Restarting the device',
    },
    {
      id: '105004',
      text: 'Moving to a different location',
    },
    {
      id: '105005',
      text: 'None of the above',
    },
  ],
  questionType: 'multiSelect',
} as IWifiWizardQuestion;

const question5 = {
  id: '104',
  question: "Which of the following describes the customer's router?",
  answers: [
    {
      id: '104001',
      text: 'Older than 4 years',
    },
    {
      id: '104002',
      text: 'Provided by ISP',
    },
    {
      id: '104003',
      text: 'Reset in the last 30 days',
    },
    {
      id: '105004',
      text: 'None of the above',
    },
  ],
  questionType: 'multiSelect',
} as IWifiWizardQuestion;

const question6 = {
  id: '107',
  question:
    "Is the customer's home an apartment, townhome or in a close neighborhood?",
  answers: [
    {
      id: '107001',
      text: 'Yes',
    },
    {
      id: '107002',
      text: 'No',
    },
  ],
  questionType: 'singleSelect',
} as IWifiWizardQuestion;

const question7 = {
  id: '108',
  question: 'Is your home larger than 2500 sq ft?',
  answers: [
    {
      id: '108001',
      text: 'Yes',
    },
    {
      id: '108002',
      text: 'No',
    },
  ],
  questionType: 'singleSelect',
} as IWifiWizardQuestion;

const question8 = {
  id: '109',
  question: 'Does your home have more than one floor?',
  answers: [
    {
      id: '109001',
      text: 'Yes',
    },
    {
      id: '109002',
      text: 'No',
    },
  ],
  questionType: 'singleSelect',
} as IWifiWizardQuestion;

const question9 = {
  id: '110',
  question: 'Which floor is the router located on?',
  answers: [
    {
      id: '110001',
      text: 'Ground',
    },
    {
      id: '110002',
      text: 'Second',
    },
    {
      id: '110003',
      text: 'Other',
    },
  ],
  questionType: 'singleSelect',
} as IWifiWizardQuestion;

const question10 = {
  id: '106',
  question: 'What is between the router and devices/rooms with issues?',
  answers: [
    {
      id: '106001',
      text: 'Drywall',
    },
    {
      id: '106002',
      text: 'Kitchen appliances',
    },
    {
      id: '106003',
      text: 'Water pipes',
    },
    {
      id: '106004',
      text: 'Solid concrete',
    },
  ],
  questionType: 'multiSelect',
} as IWifiWizardQuestion;

const question11 = {
  id: '111',
  question: 'What type of device is affected by this issue?',
  answers: [
    {
      id: '111002',
      text: 'Smartphone',
    },
    {
      id: '111001',
      text: 'Laptop',
    },
    {
      id: '111003',
      text: 'TV',
    },
    {
      id: '0111003',
      text: 'Other connected device',
    },
    {
      id: '111004',
      text: 'All devices',
    },
  ],
  questionType: 'singleSelect',
} as IWifiWizardQuestion;
//#endregion FULL QUESTION LIST

const allLocalQuestions: IWifiWizardQuestion[] = [
  question1,
  question2,
  question3,
  question4,
  question5,
  question6,
  question7,
  question8,
  question9,
  question10,
  question11,
];

//#region FULL SOLUTION LIST
const solution1 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_1',
  title: 'Reboot Router',
  description:
    'Restarting your router will reset your connection settings, interrupt malicious attacks on your network, boot unwanted devices and help resolve a wide range of issues. You should reboot your router every 30-60 days to keep things running smoothly.',
  helixArticle:
    'https://kbsolutohelix.wpengine.com/en/s_article/network-troubleshooting-for-streaming',
} as IWifiWizardSolution;

const solution2 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_2',
  title: 'Hardwire specific devices',
  description:
    'WiFi connections can be unstable and susceptible to interference. Hardwiring commonly used devices can help ensure their connections stay stable and secure. Laptops, smart TVs, gaming consoles and voice assistants are great candidates for wire connections.',
  helixArticle:
    'https://kbsolutohelix.wpengine.com/en/s_article/network-troubleshooting-for-streaming',
} as IWifiWizardSolution;

const solution3 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_3',
  title: 'Optimize router placement',
  description:
    "Router placement is an important part of your home's connectivity. While most routers may not be able to be moved very far, following these steps can help optimize their range.",
  helixArticle:
    'https://kbsolutohelix.wpengine.com/en/s_article/position-a-wireless-router-in-a-home?openInExternalBrowser=true',
} as IWifiWizardSolution;

const solution4 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_4',
  title: 'Upgrade router',
  description:
    'If bandwidth is sufficient for your most common tasks, but still find that you are suffering from issues, or you are unable to get sufficient signal everywhere in your home it may be time to upgrade your router. In general, it is best practice to update your router every 3-4 years to take advantage of the latest WiFi standards.',
  helixArticle: '',
} as IWifiWizardSolution;

const solution5 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_5',
  title: 'Upgrade bandwidth',
  description:
    'Bandwidth requirements are determined by the specific needs of each individual home. To help determine your basic bandwidth requirements follow this simple guide.',
  helixArticle:
    'https://kbsolutohelix.wpengine.com/en/s_article/bandwidth-requirements-explained?openInExternalBrowser=true',
} as IWifiWizardSolution;

const solution6 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_6',
  title: 'Wait for external issue to be resolved',
  description:
    'It is not uncommon for individual services to suffer outages. These are usually resolved quickly.',
  helixArticle: '',
} as IWifiWizardSolution;

const solution7 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_7',
  title: 'Turn off devices when not in use',
  description:
    "Close unused apps on devices connected to the network to ensure they aren't using up bandwidth in the background.",
  helixArticle:
    'https://kbsolutohelix.wpengine.com/en/s_article/network-troubleshooting-for-streaming',
} as IWifiWizardSolution;

const solution8 = {
  rank: 0, // overwritten as the recommendation is made
  id: 'R_8',
  title: 'Reboot the device',
  description:
    'Rebooting your device will reset your connection settings and can remove a few hiccups. Turn the device off, wait 30 seconds, then turn it back on.',
  helixArticle:
    'https://kbsolutohelix.wpengine.com/en/s_article/network-troubleshooting-for-streaming',
} as IWifiWizardSolution;
//#endregion FULL SOLUTION LIST

const allLocalSolutions = [
  solution1,
  solution2,
  solution3,
  solution4,
  solution5,
  solution6,
  solution7,
  solution8,
];

//#region FULL RECOMMENDATION LIST
const row5 = {
  rootCause: 'Insufficient Bandwidth',
  solutions: [solution5, solution2, solution7],
} as IWifiWizardRecommendation;

const row6 = {
  rootCause: 'Weak Signal',
  solutions: [solution1, solution3, solution2],
} as IWifiWizardRecommendation;

const row7 = {
  rootCause: 'Weak Signal',
  solutions: [solution8, solution2, solution3],
} as IWifiWizardRecommendation;

const row10 = {
  rootCause: 'Weak Signal',
  solutions: [solution3, solution4],
} as IWifiWizardRecommendation;

const row11 = {
  rootCause: 'Weak Signal',
  solutions: [solution3, solution4],
} as IWifiWizardRecommendation;

const row12 = {
  rootCause: 'Outside Issue',
  solutions: [solution6],
} as IWifiWizardRecommendation;

const row13 = {
  rootCause: 'Insufficient Bandwidth',
  solutions: [solution2, solution8],
} as IWifiWizardRecommendation;

const row14 = {
  rootCause: 'Weak Signal',
  solutions: [solution2, solution8, solution3],
} as IWifiWizardRecommendation;

const row15 = {
  rootCause: 'Weak Signal',
  solutions: [solution1, solution8, solution2],
} as IWifiWizardRecommendation;

const row16 = {
  rootCause: 'Weak Signal',
  solutions: [solution2, solution8, solution3],
} as IWifiWizardRecommendation;

const row17 = {
  rootCause: 'Weak Signal',
  solutions: [solution1, solution3, solution4],
} as IWifiWizardRecommendation;

const row18 = {
  rootCause: 'Weak Signal',
  solutions: [solution2, solution8, solution3],
} as IWifiWizardRecommendation;

const row19 = {
  rootCause: 'Weak Signal',
  solutions: [solution2, solution8, solution3],
} as IWifiWizardRecommendation;

const row20 = {
  rootCause: 'Weak Signal',
  solutions: [solution2, solution8],
} as IWifiWizardRecommendation;

const row21 = {
  rootCause: 'Weak Signal',
  solutions: [solution2, solution8, solution3],
} as IWifiWizardRecommendation;

const row22 = {
  rootCause: 'Weak Signal',
  solutions: [solution1, solution8, solution4],
} as IWifiWizardRecommendation;

const row23 = {
  rootCause: 'Weak Signal',
  solutions: [solution1, solution8, solution3],
} as IWifiWizardRecommendation;

const row26 = {
  rootCause: 'Bandwidth',
  solutions: [solution5, solution7, solution4],
} as IWifiWizardRecommendation;

const row27 = {
  rootCause: 'Bandwidth',
  solutions: [solution5, solution7, solution4],
} as IWifiWizardRecommendation;

const row28 = {
  rootCause: 'Weak Signal',
  solutions: [solution2, solution8, solution3],
} as IWifiWizardRecommendation;

const fallback = {
  rootCause: 'Fallback Solution',
  solutions: [solution1, solution2, solution3],
} as IWifiWizardRecommendation;
//#endregion

const allLocalRecommendations: IWifiWizardRecommendation[] = [
  row5,
  row6,
  row7,
  row10,
  row11,
  row12,
  row13,
  row14,
  row15,
  row16,
  row17,
  row18,
  row19,
  row20,
  row21,
  row22,
  row23,
  row26,
  row27,
  row28,
  fallback,
];

type AnswerSet = string;
const recommendationMapping: Record<AnswerSet, IWifiWizardRecommendation> = {
  '101001|103001|103005|111004': row5,
  '101001|103002|111004': row6,
  '101001|103004|111004': row7,
  '101001|103003|111004': row10,
  '103003': row11,
  '101003': row12,
  '101001|103001|111003': row13,
  '101001|103002|111003': row14,
  '101002|103001|111003': row15,
  '101002|103002|111003': row16,
  '101002': row17,
  '101001|103001|111001': row18,
  '101001|103002|111001': row19,
  '101002|103001|111001': row20,
  '101002|103002|111001': row21,
  '101001|103001|111002': row22,
  '101001|103002|111002': row23,
  '101001|103005|111002': row26,
  '101002|103001|111002': row27,
  '101002|103002|111002': row28,
  fallback: fallback,
};

export class WifiWizardApiData {
  private localQuestionsToUseListing: IWifiWizardQuestion[] = [
    this.findLocalQuestionById('101'),
    this.findLocalQuestionById('111'),
    this.findLocalQuestionById('103'),
  ];

  private localResponseQuestionBatch: IWifiWizardQuestion[] = [];
  private localResponseRecommendations: IWifiWizardRecommendation[] = [];

  public mockWifiWizardResponse(): IWifiWizardResponse {
    return ({
      batch: this.localResponseQuestionBatch,
      recommendation: this.localResponseRecommendations,
    } as unknown) as IWifiWizardResponse;
  }

  /**
   * @returns true if the last question in the mock response's batch already
   * includes an answer; false otherwise
   */
  private isLastResponseQuestionAnswered(): boolean {
    return (
      this.localResponseQuestionBatch[
        this.localResponseQuestionBatch.length - 1
      ]?.chosenAnswerIds !== undefined
    );
  }

  /**
   * @returns true if the number of questions in mock response's batch matches
   * the number of questions in the listing of desired questions to
   * ask;false otherwise
   */
  private haveAllDesiredQuestionsBeenAsked(): boolean {
    return (
      this.localResponseQuestionBatch.length ===
      this.localQuestionsToUseListing.length
    );
  }

  private mapLocalQuestionsToRecommendationRequest(
    localQuestions: IWifiWizardQuestion[]
  ): IRecommendationServiceRequest {
    return {
      questionData: localQuestions.map((question) => ({
        questionID: question.id,
        answers: question.answers.map((answer) => ({ answerID: answer.id })),
      })),
    };
  }

  private async updateRecommendation(): Promise<void> {
    if (await shouldCallRecommendationApi()) {
      // This is all handled via observable updates in RecommendationComponentApi
    } else {
      const recommendationKey = this.localResponseQuestionBatch
        .flatMap((question) =>
          !!question.chosenAnswerIds ? question.chosenAnswerIds : []
        )
        .sort()
        .join('|');

      const rec = this.mapRecommendationKey(recommendationKey);
      this.updateSolutionRanks(rec.solutions);
    }
  }

  private mapRecommendationKey(key: string): IWifiWizardRecommendation {
    let rec = recommendationMapping[key];

    // attempt to match a loose mapping before using fallback recommendation
    if (key.includes('103003')) {
      rec = recommendationMapping['103003'];
    } else if (key.includes('101003')) {
      rec = recommendationMapping['101003'];
    } else if (key.includes('101002')) {
      rec = recommendationMapping['101002'];
    }

    if (!rec) {
      rec = recommendationMapping['fallback'];
    }
    return rec;
  }

  private updateSolutionRanks(solutionArray: IWifiWizardSolution[]) {
    solutionArray.map((solution, index) => (solution.rank = index + 1));
  }

  /**
   * Updates the question list that's returned in the mock response.
   * If there are no questions in the listing, it adds the first question to the
   * list. If all of the existing questions have been answered, it adds a new
   * question to the list. If all of the questions we want to ask have been asked
   * and answered, it empties the list.
   */
  public async updateResponseQuestionBatch(): Promise<void> {
    //#region temporary code block for returning all questions in one response
    this.updateRecommendation();
    this.localResponseQuestionBatch = this.localQuestionsToUseListing;
    //#endregion temporary code block for returning all questions in one response

    // if (this.localResponseQuestionBatch.length === 0) {
    //   this.localResponseQuestionBatch.push(this.localQuestionsToUseListing[0]);
    //   return;
    // }

    // if (this.isLastResponseQuestionAnswered()) {
    //   if (this.haveAllDesiredQuestionsBeenAsked()) {
    //     this.localResponseQuestionBatch = [];
    //     this.updateRecommendation();
    //   } else if (
    //     this.localQuestionsToUseListing.length >
    //     this.localResponseQuestionBatch.length
    //   ) {
    //     this.localResponseQuestionBatch.push(
    //       this.localQuestionsToUseListing[
    //         this.localResponseQuestionBatch.length
    //       ]
    //     );
    //   } else {
    //     logger.info(
    //       'you done goofed -- the mock questions array is somehow longer than the desired questions array'
    //     );
    //     logger.info(
    //       `questionsToUseListing length: ${this.localQuestionsToUseListing.length}, responseBatch length: ${this.localResponseQuestionBatch.length}`
    //     );
    //   }
    // }
  }

  /**
   *
   * @param id the id of the question that should be found and returned
   * @returns the question whose id matches the passed in value
   */
  private findLocalQuestionById(id: string): IWifiWizardQuestion {
    const matchingQuestion = allLocalQuestions.find(
      (question) => question.id === id
    );
    // TODO: is there a better default value to avoid confusing questions?
    return matchingQuestion ? matchingQuestion : question9;
  }

  /**
   *
   * @param id the id of the solution that should be found and returned
   * @returns the solution whose id matches the passed in value
   */
  private findLocalSolutionById(id: string): IWifiWizardSolution {
    const matchingSolution = allLocalSolutions.find(
      (solution) => solution.id === id
    );
    // TODO: is there a better default value to avoid confusing solutions?
    return matchingSolution ? matchingSolution : solution5;
  }

  public mapSolutions(solutionIds: string[]): IWifiWizardSolution[] {
    return solutionIds.map((id) => this.findLocalSolutionById(id));
  }

  /**
   *
   * @param chosenAnswers the answer that will be persisted
   */
  public setAnswerToLocalQuestion(
    chosenAnswers: IWifiWizardAnsweredQuestion | undefined
  ): void {
    if (chosenAnswers !== undefined) {
      const question = this.findLocalQuestionById(chosenAnswers.questionId);
      question.chosenAnswerIds = chosenAnswers.answerId;
    }
  }
}

export const wifiWizardApiData = new WifiWizardApiData();
