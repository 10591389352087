import { useEffect, useState } from 'react';

type SESSION_KEYS = 'queryParams';

export type StoredQueryParams = {
  clientId?: string;
  authToken?: string;
  hostArray: string[];
  socoSupportSessionId?: string;
  partner: string;
};

/**
 * This can be used to to hook into session storage and get/set values
 * @param key Key where the values are put, currently only supports 'queryParams'
 * @param initialValue The initial value to set it to.
 * @param rawValue This will just return back the raw value with no JSON parsing
 */
export const useSessionStorage = <T,>(
  key: SESSION_KEYS,
  initialValue?: T,
  rawValue?: boolean
): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => {
    try {
      const sessionStorageValue = localStorage.getItem(key);
      if (typeof sessionStorageValue !== 'string') {
        localStorage.setItem(
          key,
          rawValue ? String(initialValue) : JSON.stringify(initialValue)
        );
        return initialValue;
      } else {
        return rawValue
          ? sessionStorageValue
          : JSON.parse(sessionStorageValue || 'null');
      }
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // cat throw, too.
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      const serializedState = rawValue ? String(state) : JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. Also JSON.stringify can throw.
    }
  });

  return [state, setState];
};
