import {
  IClientSessionRequest,
  IClientSessionResponse,
  IHomeGraphRequest,
  IHomeGraphResponse,
  IPinMappingResponse,
} from './contracts';
import { rxFetch } from './rx-fetch';
import { Observable, of, throwError } from 'rxjs';
import {
  DashboardEventType,
  dispatchAnalyticEvent,
  dispatchAnalyticEventOnError,
  generateClientIdAry,
  getClientId,
  sortArrayByDateKey,
  UserAnalyticEvent,
} from '../utils';
import {
  ASURION_CLIENT_VERSION,
  DEFAULT_NO_OF_DAYS,
  PartnerKeyProperties,
  PartnerKeys,
} from '../constants';
import { HomeGraphApi, homeGraphApi, listSessionApi } from '../page-api';
import { catchError, delay, first, map, tap } from 'rxjs/operators';
import { getAuthorizationHeadersObject, getAuthToken } from '../sso';
import { shouldUseHomeMgmt } from './SharedServiceUtils';

const appConfig = require('appConfig');

/**
 * This is the central agent for all things network request.
 *
 * @class NetworkScanApi
 */
export class NetworkScanApi {
  private get getListSessionsUrl(): string {
    if (shouldUseHomeMgmt()) {
      return `${appConfig.mgmtApi}/expert/sessions/listSessions`;
    } else {
      return `${appConfig.apiBase}listsessionsv2`;
    }
  }

  private get getHomeGraphsUrl(): string {
    if (shouldUseHomeMgmt()) {
      return `${appConfig.mgmtApi}/expert/sessions/getHomegraph`;
    } else {
      return `${appConfig.apiBase}retrievehomegraphv2`;
    }
  }

  private readonly postTriggerScanUrl = `${appConfig.apiBase}triggerscan`;
  private readonly postExpertFeedbackUrl = `${appConfig.mgmtApi}/expert/feedback`;
  private readonly pinMappingUrl = appConfig.pinMappingBase;

  private readonly homeGraphApi: HomeGraphApi;

  constructor(_homeGraphApi: HomeGraphApi) {
    this.homeGraphApi = _homeGraphApi;
  }

  /**
   * Get a whole home graph view. This may take a request or two to get a full picture.
   *
   * ! If you are using this in a real time scenario,you will need to poll via a `tap`.
   *
   * * Polling is built into the usages right now and does not come here since not everyone will need it.
   *
   * @param {IHomeGraphRequest} request The request which contains plenty of information about where to retrieve the data.
   * @returns {Observable<IHomeGraphResponse>}
   * @memberof NetworkScanApi
   */
  public getHomeGraphData(
    request: IHomeGraphRequest
  ): Observable<IHomeGraphResponse> {
    const { clientId, authToken, carrier, sessionId, databaseTable } = request;

    if (this.isDemo(clientId)) {
      return this.getMockHomeGraphData(sessionId);
    }

    let apiCall$: Observable<IHomeGraphResponse> = rxFetch<IHomeGraphResponse>(
      this.getHomeGraphsUrl,
      {
        method: 'POST',
        headers: this.generateSessionHeaders(authToken),
        body: JSON.stringify({
          applicationIds: generateClientIdAry({ clientId }),
          authToken,
          tool: 'soco',
          carrier: PartnerKeyProperties[carrier].tableName,
          sessionId: sessionId,
          nrOfDays: DEFAULT_NO_OF_DAYS,
          table: databaseTable,
        }),
      }
    );
    if (appConfig.develop) {
      apiCall$ = of<IHomeGraphResponse>(
        require('../json/NewHomeGraphData.json')
      );
    }
    this.homeGraphApi.toggleCachedHomeGraph(sessionId);
    return apiCall$.pipe(
      catchError(() => of({} as IHomeGraphResponse)), // this failed on once, so putting this here to save the call and the page.
      tap((response) => {
        homeGraphApi.updateHomeGraphData(sessionId, response);
      })
    );
  }

  private getMockHomeGraphData(
    sessionId: string
  ): Observable<IHomeGraphResponse> {
    console.log('Getting mock home graph data for demo');
    this.homeGraphApi.toggleCachedHomeGraph(sessionId);
    return of<IHomeGraphResponse>(
      require('../json/HomeGraphResponseData.json')
    ).pipe(
      delay(2000),
      catchError(() => of({} as IHomeGraphResponse)), // this failed on once, so putting this here to save the call and the page.
      tap((response) => {
        homeGraphApi.updateHomeGraphData(sessionId, response);
      })
    );
  }

  /**
   * Get a list of sessions for a user which can be used to get homegraph details
   *
   * @param {IClientSessionRequest} request The client request object
   * @returns {Observable<IClientSessionResponse>}
   * @memberof NetworkScanApi
   */
  public getSessionsByClientId(
    request: IClientSessionRequest
  ): Observable<IClientSessionResponse> {
    const { clientId, authToken, carrier } = request;

    if (this.isDemo(clientId)) {
      return this.getMockSessions();
    }
    if (this.isDemoFail(clientId)) {
      return this.getMockSessions(true);
    }

    return rxFetch<IClientSessionResponse>(this.getListSessionsUrl, {
      method: 'POST',
      headers: this.generateSessionHeaders(authToken),
      body: JSON.stringify({
        applicationIds: generateClientIdAry({ clientId }),
        authToken,
        tool: 'soco',
        carrier: PartnerKeyProperties[carrier].tableName,
        nrOfDays: DEFAULT_NO_OF_DAYS,
      }),
    }).pipe(
      map<IClientSessionResponse, IClientSessionResponse>((response) => {
        if (response.sessions && response.sessions.length > 0) {
          response.sessions = sortArrayByDateKey(
            response.sessions,
            'created_at'
          );
          listSessionApi.updateKnownSessions(response.sessions);
        }
        return response;
      })
    );
  }

  private getMockSessions(
    sendBlankResponse = false
  ): Observable<IClientSessionResponse> {
    if (sendBlankResponse) {
      return of<IClientSessionResponse>({} as IClientSessionResponse).pipe(
        first()
      );
    }
    return of<IClientSessionResponse>({
      sessions: [
        {
          client_id: 'demo',
          created_at: '2020-09-24 22:11:50.666',
          session_id: 'demo',
          table: '',
        },
      ],
    } as IClientSessionResponse).pipe(
      tap((response) => {
        if (response.sessions && response.sessions.length > 0) {
          listSessionApi.updateKnownSessions(response.sessions);
        }
      })
    );
  }

  /**
   * Given a PIN, get a clientId that is associated to that PIN
   *
   * @param {number} pin Unique PIN that is used to identify a customer
   * @returns {Observable<IPinMappingResponse>}
   */
  public getDataByPin(pin: number): Observable<IPinMappingResponse> {
    if (pin === 0) {
      return this.getMockDataByPin(pin);
    }

    return rxFetch<IPinMappingResponse>(`${this.pinMappingUrl}/${pin}`, {
      method: 'GET',
      headers: new Headers({
        ...getAuthorizationHeadersObject(),
        'Content-Type': 'application/json',
      }),
    }).pipe(
      dispatchAnalyticEvent(
        UserAnalyticEvent.PinRequestSuccess,
        DashboardEventType.Business
      ),
      dispatchAnalyticEventOnError(
        UserAnalyticEvent.PinRequestFailure,
        DashboardEventType.Business
      )
    );
  }

  private getMockDataByPin(pin: number): Observable<IPinMappingResponse> {
    return of<IPinMappingResponse>({
      id: 'demo',
      pin: pin,
      expiresAt: new Date(Date.now() + 3600000),
    } as IPinMappingResponse);
  }

  /**
   *
   *
   * @param {string} expertName this is the expert name from sso/cognito information
   * @param {string} feedback this is what the expert put in the feedback part
   * @param {string} authToken expert jwt
   */
  public postExpertFeedback(
    expertName: string,
    feedback: string,
    authToken: string
  ): Observable<Response> {
    return rxFetch<Response>(this.postExpertFeedbackUrl, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      }),
      body: JSON.stringify({
        expertName,
        feedback,
      }),
    });
  }

  /**
   * Will send a dynamic request to TLV to push a Dynamic component for Verizon.
   *
   * ! This is a Verizon only feature right now.
   *
   * @param {string} clientId This could be a either a '[UUID]' or a 'UUID'
   * @param {PartnerKeys} partnerId The Partner key either 'att' or 'vzw' as it stands
   *
   * @returns {Observable<Response>}
   * @memberof NetworkScanApi
   */
  public sendScanRequestToCustomer(
    clientId: string,
    partnerId: PartnerKeys
  ): Observable<Response> {
    if (this.isDemo(clientId)) {
      return this.mockSendScanRequestToCustomer();
    }

    const clientIdFormatted = getClientId(clientId);
    return rxFetch(this.postTriggerScanUrl, {
      method: 'POST',
      body: JSON.stringify({
        clientId: clientIdFormatted,
        authToken: getAuthToken(),
        Headline: "How's your home Wi-Fi doing?",
        Message: "Let's run a network check up to find out!",
        NotificationType: 'openApp',
      }),
      headers: new Headers({
        'x-asurion-applicationid': clientIdFormatted,
        'x-asurion-partnerid': PartnerKeyProperties[partnerId].name,
        'x-asurion-clientversion': ASURION_CLIENT_VERSION,
        partner_id: partnerId,
      }),
    }).pipe(
      dispatchAnalyticEvent(
        UserAnalyticEvent.InitiateScanRequestSuccess,
        DashboardEventType.Business
      ),
      dispatchAnalyticEventOnError(
        UserAnalyticEvent.InitiateScanRequestFailure,
        DashboardEventType.Business
      )
    );
  }

  private mockSendScanRequestToCustomer(): Observable<Response> {
    return of({} as Response).pipe(delay(2000));
  }

  private generateSessionHeaders(authToken: string): Headers {
    if (shouldUseHomeMgmt()) {
      return new Headers({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      });
    } else {
      return new Headers({ 'Content-Type': 'application/json' });
    }
  }

  private isDemo(clientId?: string): boolean {
    return !!clientId && clientId.toLowerCase() === 'demo';
  }

  private isDemoFail(clientId?: string): boolean {
    return !!clientId && clientId.toLowerCase() === 'demo-fail';
  }
}

export const networkScanApi = new NetworkScanApi(homeGraphApi);
