import { logger } from '@home-mgmt-shared/logger';
import { Entry } from 'contentful';
import { ContentfulModel } from '../enums';
import { ExpertTipTransformer } from './ExpertTipTransformer';
import { IContentTransformer } from './IContentTransformer';

export class TransformerHandler {
  private readonly typeMapper: Record<
    ContentfulModel,
    IContentTransformer<any, any> | null
  >;
  constructor() {
    // * To add a new transformer add to this mapper, and add your Transformer here
    this.typeMapper = {
      [ContentfulModel.ExpertTip]: new ExpertTipTransformer(),
      [ContentfulModel.Container]: null,
      [ContentfulModel.Tag]: null,
    };
  }

  public transformContent(
    contentfulModel: ContentfulModel,
    content: Entry<any>
  ): any {
    const mapper = this.typeMapper[contentfulModel];
    if (mapper) {
      return mapper.transform(content);
    } else {
      logger.debug(
        `${contentfulModel} is not a known, mapped content type. Please add it to the map`
      );
    }
    return undefined;
  }
}
