import { logger } from '@home-mgmt-shared/logger';
import { FullStoryAPI, getCurrentSessionUrl } from 'react-fullstory';
import { analytics, DashboardEventType, UserAnalyticEvent } from '../analytics';

const fullStorySessionCreationEvent = (eventType: UserAnalyticEvent) => {
  const sessionUrl = getCurrentSessionUrl(true);
  if (sessionUrl) {
    analytics.dispatch(
      UserAnalyticEvent.FullstorySessionCreated,
      DashboardEventType.FullStory,
      {
        sessionUrl,
        eventType,
      }
    );
  }
};

export const setIdentityForFullStory = (
  clientId: string,
  socoSupportSessionId?: string
) => {
  try {
    FullStoryAPI('identify', clientId, {
      supportSessionId_str: socoSupportSessionId ?? 'no_support_session_found',
    });
    fullStorySessionCreationEvent(UserAnalyticEvent.UserUpdated);
  } catch (error) {
    logger.error('Analytics', error);
  }
};
