import React from 'react';
import {
  DisplayString,
  PartnerKeyProperties,
  PartnerKeys,
} from '../../../constants';
import {
  ContainerSteps,
  PreScanContentContainer,
  RoutePaths,
} from '../../../components';
import {
  StoredQueryParams,
  useEffectOnce,
  useRxApi,
  useSessionStorage,
} from '../../../hooks';
import { themeApi } from '../../../page-api';
import { VerizonInitiateScanButton } from './VerizonInitiateScanButton';
import { ScanInstructionPropsV2 } from '../../contracts';
import { map } from 'rxjs/operators';
import { VerizonInitiateScanButtonV2 } from './index';

const VzwDynamicComponentLight = require('../../../img/default/vzw/start-checkup-light.png');
const VzwDynamicComponentDark = require('../../../img/default/vzw/start-checkup-dark.png');

export const VerizonScanInstructionContentV2 = ({
  navigationProps$,
  navigationButtonEnabled$,
}: ScanInstructionPropsV2) => {
  const { result: isDarkMode } = useRxApi(
    themeApi.currentTheme$.pipe(map(() => themeApi.isDarkMode)),
    {
      initialValue: false,
    }
  );
  const [params] = useSessionStorage<StoredQueryParams>('queryParams');

  useEffectOnce(() => {
    navigationProps$.next({
      pathname: RoutePaths.Scanning,
    });
  });

  const contentSteps: ContainerSteps[] = [
    {
      contentHtml: `Confirm the <strong>${
        PartnerKeyProperties[PartnerKeys.vzw].appName
      } app </strong> has been downloaded.`,
    },
    {
      contentHtml: `Send the customer a notification to start their scan in the ${
        PartnerKeyProperties[PartnerKeys.vzw].appName
      } app.`,
      children: params.clientId && (
        <VerizonInitiateScanButtonV2
          clientId={params.clientId}
          variant="primary"
          navigationButtonEnabled$={navigationButtonEnabled$}
        />
      ),
    },
    {
      contentHtml: `
        Instruct the customer to tap <strong>${DisplayString.VzwDynamicComponentButton}</strong>. The scan should take no more than 2 minutes.
      `,
      imageSrc: isDarkMode ? VzwDynamicComponentDark : VzwDynamicComponentLight,
      imageAltText: `Arrow pointing to ${DisplayString.VzwDynamicComponentButton}`,
    },
  ];

  return <PreScanContentContainer v2={true} steps={contentSteps} />;
};
