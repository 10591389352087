import React, { useState } from 'react';
import { BaseButton, ScanFailure, ScanningV2 } from '../../index';
import { getPartner } from '../../../partner/index';
import { StoredQueryParams, useRxApi, useSessionStorage } from '../../../hooks';
import { IClientSessionResponse, networkScanApi } from '../../../services';
import { catchError, delay, repeat, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DisplayString, PartnerKeys } from '../../../constants';
import { ResultsPageState } from '../../../pages';
import { UserAnalyticEvent } from '../../../utils';
import { listSessionApi } from '../../../page-api';
import { getAuthToken } from '../../../sso';

const TIME_TO_WAIT_BETWEEN_CALLS = 20000;
const NUMBER_OF_CALLS_TO_MAKE = 9;

interface ScanningPageComponentProps {
  resultsPageState: ResultsPageState;
  setResultsPageState: (resultsPageState: ResultsPageState) => void;
}

/**
 * Will begin scanning, and shows user an animation.
 * This can only be called with a valid authToken and clientId (or MDN) in
 * the application state.
 *
 */
export const ScanningPageComponent = ({
  resultsPageState,
  setResultsPageState,
}: ScanningPageComponentProps) => {
  const [params] = useSessionStorage<StoredQueryParams>('queryParams');
  const [hasResults, setHasResults] = useState(false);

  const isScanning = () => resultsPageState === ResultsPageState.Scanning;

  const clientId = params && params.clientId;

  useRxApi<IClientSessionResponse, any>(
    networkScanApi
      .getSessionsByClientId({
        clientId: clientId!,
        authToken: getAuthToken(),
        carrier: getPartner(),
      })
      .pipe(
        catchError((err) => of(err)), // we want to catch this nicely here so it doesn't stop going.
        tap((result) => {
          if (result.sessions && result.sessions.length > 0) {
            setHasResults(true);
          }
        }),
        delay(TIME_TO_WAIT_BETWEEN_CALLS),
        repeat(clientId === 'demo-fail' ? 1 : NUMBER_OF_CALLS_TO_MAKE)
      ),
    {
      initialValue: {} as IClientSessionResponse,
      onComplete: () => {
        setResultsPageState(ResultsPageState.ScanFailed);
      },
    }
  );

  // wait until the user clicks a button to display scan results
  if (hasResults && isScanning()) {
    setResultsPageState(ResultsPageState.ScanResults);
  }

  const ScanningV2Text = () => {
    return (
      <>
        <span className="scanning-v2-top-text">
          {!isScanning() ? 'Waiting to fetch data...' : 'Fetching data'}
        </span>
        {isScanning() && <span>This can take up to 2 minutes.</span>}
      </>
    );
  };

  const onClickRefresh = () => {
    setResultsPageState(ResultsPageState.Scanning);
  };

  const ScanFailed = () => {
    return (
      <div className="scan-failed-animation-container">
        <ScanFailure />
        <div className="scan-failed-subtitle">
          <h1>{DisplayString.ScanFailedUnableToComplete}</h1>
          <h2>{DisplayString.ScanFailedAskCustomerToCheckConnection}</h2>
          <h2>{DisplayString.ScanFailedAfterCustomerRestartedRefresh}</h2>
        </div>
        <BaseButton
          analyticName={UserAnalyticEvent.ScanUnsuccessfulRefreshButton}
          onClick={onClickRefresh}
        >
          Refresh
        </BaseButton>
      </div>
    );
  };

  return (
    <div className="scan-progress-container v2">
      <div className="scanning-v2-container">
        {resultsPageState === ResultsPageState.ScanFailed ? (
          <ScanFailed />
        ) : (
          <>
            <ScanningV2
              className={`scanning-v2${
                isScanning() ? ' scanning-in-progress' : ''
              }`}
              isScanning={isScanning()}
            />
            <div className="scanning-v2-text-container">
              <ScanningV2Text />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
