import React, { FunctionComponent, useEffect } from 'react';
import Auth from './auth';
import { useAuthentication } from './AuthenticationProvider';
import { useRouter, useSessionStorage } from '../hooks';
import { analytics, getSessionData } from '../utils';
import { flattenUrlConfigs, mergePartnerContext } from '../partner';
import { MergedPartnerConfig } from '../partner/utils/interface';
import { updateTweekContext } from '../tweek';

export const withAuthentication = <P extends object>(
  Component: React.ComponentType<P>
): FunctionComponent<P> => (props) => {
  const { isLoggedIn } = useAuthentication();

  const [queryParams, setParams] = useSessionStorage<Record<string, string>>(
    'queryParams',
    {}
  );

  const { query, location } = useRouter();
  useEffect(() => {
    const params: Record<string, any> = {
      ...query,
    };

    if (params.clientId || params.partner) {
      setParams({ ...queryParams, ...params });
    }
    if (!isLoggedIn) {
      setParams({ ...queryParams, ...params });
      Auth.federatedSignIn({
        customProvider: 'sso.asurion.com',
      });
    } else {
      const sessionData = getSessionData('queryParams');
      flattenUrlConfigs(sessionData);

      const mergedPartnerContext: MergedPartnerConfig = mergePartnerContext({});
      updateTweekContext({ partner: mergedPartnerContext.partnerNameUrl });
      analytics.updateProperties({
        partner: mergedPartnerContext.partnerNameUrl,
        userId: mergedPartnerContext.clientId,
        sessionId: mergedPartnerContext.socoSupportSessionId,
        appName: 'Expert Dashboard',
      });
    }
    // ! Not using this override will cause query params to be put below and will kill the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, location.pathname, query, setParams]);
  return isLoggedIn ? <Component {...props} /> : null;
};
