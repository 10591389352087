import React from 'react';
import { hot } from 'react-hot-loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TweekProvider } from '../tweek';
import { FullStory, initializeLogger } from '../utils';
import { AppRoutes } from './routing';

initializeLogger();

const AppComponent = () => {
  return (
    <>
      <FullStory />
      <TweekProvider>
        <ToastContainer />
        <AppRoutes />
      </TweekProvider>
    </>
  );
};

export const App = hot(module)(AppComponent);
